import React, { useState, useEffect, useRef } from 'react';
import Calendar from 'tui-calendar'; // TUI Calendar (v1.x)
import { Container, Row, Col, Button } from 'react-bootstrap';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'; // Import left and right icons
import 'tui-calendar/dist/tui-calendar.css'; // Default TUI Calendar styles
import EventDetailsCard from './EventDetailsCard'; // Import the EventDetailsCard component
import { useTranslation } from './TranslationProvider'; // Import the useTranslation hook
import './CalendarStyles.css'; // Import responsive styles

const CalendarPage = () => {
  const { translate } = useTranslation(); 
  const calendarRef = useRef(null); // Ref for TUI Calendar instance
  const [events, setEvents] = useState([]); // Store events
  const [selectedEvent, setSelectedEvent] = useState(null); // For storing clicked event
  const [currentDate, setCurrentDate] = useState(new Date()); // Store current calendar date

  // Function to fetch events from your REST API
  const fetchEvents = async () => {
    try {
      const response = await fetch('/api/calendar/events'); // Your API route
      const data = await response.json();

      const eventsData = data.map(event => ({
        id: event.id,
        calendarId: '1',
        title: event.summary,
        body: event.description || 'No description available',
        start: event.start.dateTime || event.start.date,
        end: event.end.dateTime || event.end.date,
        category: 'time',
        isReadOnly: true,
      }));

      setEvents(eventsData); // Set events to state
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  // Initialize TUI Calendar only once
  useEffect(() => {
    const calendarInstance = new Calendar(calendarRef.current, {
      defaultView: 'month',
      useDetailPopup: false,
      taskView: false,
      scheduleView: ['time'],
      isReadOnly: true, // Make sure the calendar is fully read-only
      month: {
        startDayOfWeek: 0,
        isAlways6Weeks: false, // Only show the required number of weeks
        visibleWeeksCount: null, // Dynamically adjust the number of weeks
      },
      template: {
        time: (schedule) => `<p>${schedule.title}</p>`,
      },
    });

    // Handle event click to open the card in the center
    calendarInstance.on('clickSchedule', (event) => {
      setSelectedEvent(event.schedule); // Set clicked event data
    });

    calendarRef.current.calendarInstance = calendarInstance;

    return () => {
      calendarInstance.destroy();
    };
  }, []);

  // Update calendar with new events after fetching
  useEffect(() => {
    if (calendarRef.current && calendarRef.current.calendarInstance) {
      calendarRef.current.calendarInstance.clear();
      calendarRef.current.calendarInstance.createSchedules(events);
    }
  }, [events]);

  useEffect(() => {
    fetchEvents(); // Fetch events once when component mounts
  }, []);

  // Function to update the current month when navigating
  const updateCurrentDate = () => {
    const calendarInstance = calendarRef.current.calendarInstance;
    setCurrentDate(new Date(calendarInstance.getDate().getTime()));
  };

  // Move to previous month
  const handlePrevMonth = () => {
    calendarRef.current.calendarInstance.prev();
    updateCurrentDate();
  };

  // Move to next month
  const handleNextMonth = () => {
    calendarRef.current.calendarInstance.next();
    updateCurrentDate();
  };

  return (
    <Container className="py-4">
      {/* Calendar Controls */}
      <Row className="justify-content-center mb-4">
        <Col lg={4} md={4} sm={8} className="text-center">
          {/* Current Month Display */}
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <BiChevronLeft size={48} color="#1173bd" className="back-arrow" onClick={handlePrevMonth} />
            <h4 style={{ fontSize:"32", color: "#d7cdc1"}}>{translate(currentDate.toLocaleString('en-US', { month: 'long' })) + " " + currentDate.toLocaleString('default', { year: 'numeric' })}</h4>
            <BiChevronRight size={48} color="#1173bd" className="back-arrow" onClick={handleNextMonth} />
          </div>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col lg={12} md={12} sm={12}>
          {/* Calendar container with responsive height */}
          <div
            ref={calendarRef}
            className="responsive-calendar"
          ></div>
        </Col>
      </Row>

      {/* Event Details Card */}
      {selectedEvent && (
        <EventDetailsCard
          event={selectedEvent}
          onClose={() => setSelectedEvent(null)}
        />
      )}
    </Container>
  );
};

export default CalendarPage;
