import React, { useState } from 'react';
import { useTranslation } from './TranslationProvider';
import enIcon from '../images/en.png';
import frIcon from '../images/fr.png';

const buttonSize = '24px';

const LanguageSwitcher = () => {
  const { language, setLanguage } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    setIsOpen(false); // Close the dropdown after selecting a language
  };

  return (
    <div className="language-switcher" style={{ position: 'relative', display: 'inline-block', width: buttonSize, height: buttonSize }}>
      <button
        onClick={toggleDropdown}
        style={{
          background: 'transparent',
          border: 'none',
          cursor: 'pointer',
          padding: 0,
          width: buttonSize,
          height: buttonSize,
        }}
      >
        <img
          src={language === 'en' ? enIcon : frIcon}
          alt={language === 'en' ? 'English' : 'French'}
          width={buttonSize}
          height={buttonSize}
          style={{ display: 'block' }}
        />
      </button>
      {isOpen && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            width: buttonSize,
            backgroundColor: 'transparent',
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
            margin: 0,
          }}
        >
          {language !== 'en' && (
            <button
              onClick={() => handleLanguageChange('en')}
              style={{
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
                width: buttonSize,
                height: buttonSize,
                padding: 0,
                margin: 0,
                display: 'block',
              }}
            >
              <img src={enIcon} alt="English" width={buttonSize} height={buttonSize} style={{ display: 'block' }} />
            </button>
          )}
          {language !== 'fr' && (
            <button
              onClick={() => handleLanguageChange('fr')}
              style={{
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
                width: buttonSize,
                height: buttonSize,
                padding: 0,
                margin: 0,
                display: 'block',
              }}
            >
              <img src={frIcon} alt="French" width={buttonSize} height={buttonSize} style={{ display: 'block' }} />
            </button>
          )}
          {/* Add more buttons for other languages if needed */}
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
