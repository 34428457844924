import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Section from './Section'; // Import the Section component
import { useTranslation } from './TranslationProvider'; // Import the useTranslation hook
import './home.css';
import ContactForm from './ContactForm';
import { ReactComponent as FullLogo } from '../images/xalliance_full.svg';
import BeybladeX from '../images/beybladeX.png';
import SocialMediaIcons from './SocialMediaIcons';

const HomePage = () => {
    const { translate } = useTranslation();

    return (
        <Container fluid className="homepage">
            {/* First Section (image on the right) */}
            <Section direction="left">
                <Row className="align-items-center">
                    <Col md={6}>
                        <img src={BeybladeX} alt="Beyblade X Logo" className="img-fluid" />
                    </Col>
                    <Col md={6} className="d-flex justify-content-center">
                        <div className="text-section">
                            <h2>{translate("welcome")}</h2>
                            <p>{translate("welcomeMessage")}</p>
                            <p>{translate("welcomeMessage2")}</p>
                            <p style={{ fontWeight: "bold" }}>{translate("areYouReady")}</p>
                        </div>
                    </Col>
                </Row>
            </Section>

            {/* Second Section (image on the left) */}
            <Section direction="right">
                <Row className="align-items-center">
                    <Col md={6} className="d-flex justify-content-center">
                        <div className="text-section">
                            <h2>{translate("missionTitle")}</h2>
                            <ul>
                                <li>{translate("mission1")}</li>
                                <li>{translate("mission2")}</li>
                                <li>{translate("mission3")}</li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={6}>
                        <FullLogo alt="X-Alliance Logo" className="img-fluid" />
                    </Col>
                </Row>
            </Section>

            {/* Third Section (centered form) */}
            {/* Third Section (centered form) */}
            <Section direction="right">
                <div className="d-flex justify-content-center flex-column align-items-center">
                    <h2 className="text-center">{translate("contactUs")}</h2>
                    <ContactForm />
                    <br />
                    <SocialMediaIcons />
                </div>
            </Section>
        </Container>
    );
};

export default HomePage;
