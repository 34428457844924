import React from 'react';
import { Form } from 'react-bootstrap';

const SearchBar = ({ searchTerm, onChange, placeholder }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', padding: '12px' }}>
      <Form.Control
        className='search-bar'
        type="text"
        style={{maxWidth: '650px'}}
        placeholder={placeholder}
        value={searchTerm}
        onChange={onChange}
      />
    </div>
  );
};

export default SearchBar;
