import React, { useState } from 'react';
import { Container, Modal, Nav } from 'react-bootstrap';
import { useTranslation } from './TranslationProvider';
import ContactForm from './ContactForm';

const Footer = () => {
  const { translate } = useTranslation();
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <footer className="metallic text-center py-3 footer">
      <Container>
        <Nav.Link href="#copyright" className="copyright">{translate('copyright')}</Nav.Link>
      </Container>

      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{translate('contactUs')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ContactForm onClose={handleClose} />
        </Modal.Body>
      </Modal>
    </footer>
  );
};

export default Footer;
