import React, { createContext, useState, useContext, useEffect } from 'react';
import translations from '../translations.json'; // Import the JSON file

const TranslationContext = createContext();

export const useTranslation = () => {
  return useContext(TranslationContext);
};

export const TranslationProvider = ({ children }) => {
  const [language, setLanguage] = useState('en'); // Default language is 'en'

  // When the component mounts, check if there is a cached language in localStorage
  useEffect(() => {
    const cachedLanguage = localStorage.getItem('language');
    if (cachedLanguage) {
      setLanguage(cachedLanguage);
    }
  }, []);

  // Whenever the language changes, update localStorage
  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
  };

  const translate = (key) => {
    return translations[language][key] || key; // Fallback to key if translation is missing
  };

  return (
    <TranslationContext.Provider value={{ language, setLanguage: changeLanguage, translate }}>
      {children}
    </TranslationContext.Provider>
  );
};
