import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import { useMediaQuery } from '@mui/material';
import googleMapIcon from '../images/googleMap.png';
import websiteIcon from '../images/website.png';

const Leaderboard = ({ title, data, columnNb = 8, searchTerm, address, link, showLinks = true, fullDisplay = false }) => {
  const [open, setOpen] = useState(true); // Uncollapsed by default
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState(data);

  // Media queries to determine the screen size
  const isSmallScreen = useMediaQuery('(max-width: 600px)');
  const isMediumScreen = useMediaQuery('(min-width: 300px) and (max-width: 800px)');
  const isLargeScreen = useMediaQuery('(min-width: 800px)');

  // Set font size based on screen size: 12px for small, 14px for medium, and 16px for large
  const fontSize = isSmallScreen ? '12px' : isMediumScreen ? '16px' : '16px';

  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    // Validate data on component mount
    if (data && Array.isArray(data) && data.length > 0 && Array.isArray(data[0])) {
      console.log('Valid table data');
    } else {
      console.error('Invalid or missing table data');
    }
  }, [data]);

  useEffect(() => {
    // Filter the data based on the search term, but always show the first row (header)
    if (searchTerm) {
      const searchTermLower = searchTerm.toLowerCase();

      if (title.toLowerCase().includes(searchTermLower)) {
        setFilteredData(data); // Always show the full table if the title matches the search term
      } else {
        const filtered = data.filter((row, index) =>
          index === 0 || // Always include the first row (header)
          row.some((cell) => cell.toString().toLowerCase().includes(searchTermLower))
        );
        setFilteredData(filtered);
      }
    } else {
      setFilteredData(data); // Reset to all data if searchTerm is empty
    }
  }, [searchTerm, data]);

  // Estimate a max width based on the number of columns and typical width of each column
  const columnWidth = 150; // assuming each column needs about 150px width
  const maxWidth = columnNb * columnWidth; // calculate the max width for the table

  if (!fullDisplay && (!filteredData || !Array.isArray(filteredData) || filteredData.length === 1)) {
    return null;
  }

  return (
    <div className="leaderboard" style={{ maxWidth: `${maxWidth}px`, margin: '0' }}>
      <Card className="leaderboard-card" style={{ maxWidth: `${maxWidth}px`, margin: '0' }}>
      <CardContent onClick={handleToggle} className="leaderboard-title" style={{ cursor: 'pointer' }}>
        <div style={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', alignItems: 'center', position: 'relative' }}>
          <div style={{ textAlign: 'center', flex: 1, wordBreak: 'break-word' }}>{title}</div>
          {showLinks && (
            <div style={{ display: 'flex', justifyContent: isSmallScreen ? 'center' : 'flex-end', gap: '10px', marginTop: isSmallScreen ? '10px' : '0' }}>
              {link && (
                <img
                  src={websiteIcon}
                  alt="Website"
                  style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                  onClick={() => window.open(link, '_blank')}
                />
              )}
              {address && (
                <img
                  src={googleMapIcon}
                  alt="Google Maps"
                  style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                  onClick={() => window.open(`https://maps.google.com?q=${encodeURIComponent(address)}`, '_blank')}
                />
              )}
            </div>
          )}
        </div>
      </CardContent>


        <TableContainer
          component={Paper}
          className="table-container"
          style={{ maxHeight: (fullDisplay) ? 'infinite' : '500px', overflowX: 'auto', width: '100%' }}
          stickyHeader
        >
          <Table sx={{ width: '100%' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {filteredData[0]?.slice(0, columnNb).map((header, i) => (
                  <TableCell
                    key={i}
                    sx={{
                      fontWeight: 'bold',
                      fontSize: fontSize,
                      padding: '10px',
                    }}
                    className="table-header"
                  >
                    {i !== 0 ? header : '#'}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={columnNb} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                filteredData.slice(1).map((row, rowIndex) => {
                  if (!Array.isArray(row)) {
                    console.error(`Invalid row at index ${rowIndex}:`, row);
                    return null; // Skip rendering for invalid rows
                  }

                  const adjustedRow = row
                    .slice(0, columnNb)
                    .concat(Array(Math.max(0, columnNb - row.length)).fill('-'));

                  return (
                    <TableRow
                      key={rowIndex}
                      className={rowIndex % 2 === 0 ? 'even-row' : 'odd-row'}
                    >
                      {adjustedRow.map((cell, cellIndex) => {
                        const cellValue = cell || '-';
                        return (
                          <TableCell
                            key={cellIndex}
                            sx={{
                              fontSize: fontSize,
                              padding: '8px',
                            }}
                          >
                            {cellValue}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </div>
  );
};

export default Leaderboard;
