import React from 'react';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineOppositeContent, TimelineDot } from '@mui/lab';
import { Typography, useMediaQuery, Box } from '@mui/material';
import TournamentCard from './TournamentCard';
import Section from './Section';
import { Container, Row, Col } from 'react-bootstrap';

const TournamentsTimeline = ({ tournaments, searchTerm, onSelectTournament }) => {
  const isMobile = useMediaQuery('(max-width: 768px)'); // Detect if screen is mobile

  // Sort tournaments by season in descending order, season is a date string
  tournaments.sort((a, b) => new Date(b.season) - new Date(a.season));

  // Function to filter tournaments based on the search term
  const filteredTournaments = tournaments.filter((tournament) => {
    const top3 = [
      tournament.data[1][1], // Assuming player names are in this format
      tournament.data[2][1],
      tournament.data[3][1],
    ];

    // Convert search term to lowercase for case-insensitive comparison
    const lowerSearchTerm = searchTerm.toLowerCase();

    // Check if the search term matches the season, title, or any top 3 player names
    return (
      tournament.season.toLowerCase().includes(lowerSearchTerm) ||
      tournament.title.toLowerCase().includes(lowerSearchTerm) ||
      top3.some((player) => player.toLowerCase().includes(lowerSearchTerm))
    );
  });

  if (filteredTournaments.length === 0) {
    return <p>No tournaments available.</p>;
  }

  // Mobile view: Display tournaments in a single-column layout
  if (isMobile) {
    return (
      <Container>
        {filteredTournaments.map((tournament, index) => (
          <Row key={index} className="mb-4">
            <Section direction={index % 2 == 0 ? "right" : "left"}>
              <Col>
                <Typography variant="h5" align="center" className="tournament-season">
                  {tournament.season} {/* Display the season above the card */}
                </Typography>
                <TournamentCard
                  tournament={tournament}
                  searchTerm={searchTerm}
                  onClick={() => onSelectTournament(tournament)}
                />
              </Col>
            </Section>
          </Row>
        ))}
      </Container>
    );
  }

  // Desktop view: Display tournaments in the timeline layout
  return (
    <Timeline position="alternate">
      {filteredTournaments.map((tournament, index) => (
        <TimelineItem key={index} style={{ minHeight: '300px' }}>
            {/* Opposite Content (e.g., season) */}
            <TimelineOppositeContent>
              <Typography variant="h5" color="white" className="tournament-season">
                {tournament.season} {/* Display the season */}
              </Typography>
            </TimelineOppositeContent>

            {/* Timeline Separator with Dot and Connector */}
            <TimelineSeparator>
              <TimelineDot className="tournament-dot" />
              {index < filteredTournaments.length - 1 && <TimelineConnector className="tournament-line" />}
            </TimelineSeparator>

            {/* Timeline Content (The Tournament Card) */}
            <TimelineContent>
              <TournamentCard
                tournament={tournament}
                searchTerm={searchTerm}
                onClick={() => onSelectTournament(tournament)}
              />
            </TimelineContent>
          
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default TournamentsTimeline;
