import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import BackArrow from './BackArrow';
import Leaderboard from './Leaderboard';
import SearchBar from './SearchBar';

import { useTranslation } from './TranslationProvider';

const FullLeaderboard = ({ title, data, address, link, onBack, fullDisplay = true }) => {
    const { translate } = useTranslation();
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <Container style={{ padding: '24px' }}>
            <BackArrow size={48} color="#1173bd" onClick={onBack} />
            <SearchBar
                searchTerm={searchTerm}
                onChange={handleSearchChange}
                placeholder={translate('searchPlayers')}
                />
            <Leaderboard
                title={title}
                data={data}
                address={address}
                searchTerm={searchTerm}
                link={link}
                fullDisplay={fullDisplay} // Optional: full display or compact
            />
        </Container>
    );
};

export default FullLeaderboard;
