import React from 'react';
import { Container } from 'react-bootstrap';
import TournamentsTimeline from './TournamentsTimeline';
import Loading from './Loading';
import useLeaderboardCache from './useLeaderboardCache';

const Tournaments = ({ onSelectLeaderboard, searchTerm }) => {
  const { leaderboardData, loading } = useLeaderboardCache();

  if (loading) {
    return <Loading style={{ paddingTop: '3rem' }} />;
  }

  const tournaments = leaderboardData.filter((leaderboard) => leaderboard.type === 'Unique');

  return (
    <Container style={{ padding: '24px' }}>
      <TournamentsTimeline
        tournaments={tournaments}
        searchTerm={searchTerm}
        onSelectTournament={onSelectLeaderboard}
      />
    </Container>
  );
};

export default Tournaments;
