import React from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import { useTranslation } from './TranslationProvider';

// Import the social media icons
import discordIcon from '../images/discord.png';
import facebookIcon from '../images/facebook.png';
import twitterIcon from '../images/twitter.png';
import youtubeIcon from '../images/youtube.png';
import patreonIcon from '../images/patreon.png';

const SocialMediaIcons = () => {
  const [showToast, setShowToast] = React.useState(false);
    const { translate } = useTranslation();

  const handleClick = (e) => {
    e.preventDefault();
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000); // Hide the toast after 3 seconds
  };

  return (
    <div className="social-media-icons">
      <a href="#discord" onClick={handleClick}>
        <img src={discordIcon} alt="Discord" width="32" height="32" />
      </a>
      <a href="#facebook" onClick={handleClick}>
        <img src={facebookIcon} alt="Facebook" width="32" height="32" />
      </a>
      <a href="#twitter" onClick={handleClick}>
        <img src={twitterIcon} alt="Twitter" width="32" height="32" />
      </a>
      <a href="#patreon" onClick={handleClick}>
        <img src={patreonIcon} alt="Patreon" width="32" height="32" />
      </a>
      <a href="#youtube" onClick={handleClick}>
        <img src={youtubeIcon} alt="YouTube" width="32" height="32" />
      </a>

      <ToastContainer position="bottom-center" className="p-3">
        <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
          <Toast.Body className="text-dark">{ translate("comingSoonSocialMedia") }</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export default SocialMediaIcons;
