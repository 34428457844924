import React, { useState, useEffect } from 'react';
import './NewsTicker.css'; // Import the custom CSS
import { useTranslation } from './TranslationProvider';
import Loading from './Loading';
import axios from 'axios';

const NewsTicker = () => {
  const { language } = useTranslation(); // Get the language from the translation context
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading
      try {
        const response = await axios.get('/api/news/news');
        // response.data format: [{ en, fr, link }]
        setNews(response.data);
      } catch (error) {
        console.error('Error fetching news:', error);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchData();
  }, [language]); // Re-fetch data when the language changes

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="news-ticker-container">
      <div className="news-ticker">
        <span className="dot-separator"> • </span>
        {news.map((item, index) => {
          const newsText = item[language]; // Get news text based on selected language (en or fr)
          const newsLink = item.link; // Get the link

          return (
            <span key={index} className="news-item-wrapper">
              {/* If link exists, wrap news in an anchor tag */}
              {newsLink ? (
                <a href={newsLink} className="news-item-link" target="_blank" rel="noopener noreferrer">
                  {newsText}
                </a>
              ) : (
                <span className="news-item">{newsText}</span>
              )}
              {index < news.length - 1 && <span className="dot-separator"> • </span>}
            </span>
          );
        })}
        <span className="dot-separator"> • </span>
        {/* Repeat news for continuous scrolling */}
        {news.map((item, index) => {
          const newsText = item[language]; // Get news text based on selected language
          const newsLink = item.link; // Get the link

          return (
            <span key={`duplicate-${index}`} className="news-item-wrapper">
              {newsLink ? (
                <a href={newsLink} className="news-item-link" target="_blank" rel="noopener noreferrer">
                  {newsText}
                </a>
              ) : (
                <span className="news-item">{newsText}</span>
              )}
              {index < news.length - 1 && <span className="dot-separator"> • </span>}
            </span>
          );
        })}
        <span className="dot-separator"> • </span>
        {/* Repeat news for continuous scrolling */}
        {news.map((item, index) => {
          const newsText = item[language]; // Get news text based on selected language
          const newsLink = item.link; // Get the link

          return (
            <span key={`duplicate-${index}`} className="news-item-wrapper">
              {newsLink ? (
                <a href={newsLink} className="news-item-link" target="_blank" rel="noopener noreferrer">
                  {newsText}
                </a>
              ) : (
                <span className="news-item">{newsText}</span>
              )}
              {index < news.length - 1 && <span className="dot-separator"> • </span>}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default NewsTicker;
