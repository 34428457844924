import { useTranslation } from './TranslationProvider';
import { Container, Row } from 'react-bootstrap';

import ContactForm from './ContactForm';

const ContactUsPage = () => {
    const { translate } = useTranslation();

    return (
        <Container className="py-4">
            <Row>
                <ContactForm />
            </Row>
        </Container>
    )
}

export default ContactUsPage;