import { useState, useEffect } from 'react';
import axios from 'axios';

const CACHE_KEY = 'leaderboardData';
const CACHE_TIMESTAMP_KEY = 'leaderboardCacheTimestamp';
const CACHE_DURATION = 60 * 60 * 1000; // 1 hour in milliseconds

const useLeaderboardCache = () => {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [loading, setLoading] = useState(true);

  const isCacheExpired = (timestamp) => {
    const currentTime = new Date().getTime();
    return currentTime - timestamp > CACHE_DURATION;
  };

  const fetchLeaderboardData = async () => {
    try {
      const response = await axios.get('/api/spreadsheet/leaderboards');
      const data = response.data;

      // Add an index as an ID to each leaderboard
      const indexedData = data.map((leaderboard, index) => ({ ...leaderboard, id: index }));

      // Cache the leaderboard data and the current timestamp
      localStorage.setItem(CACHE_KEY, JSON.stringify(indexedData));
      localStorage.setItem(CACHE_TIMESTAMP_KEY, new Date().getTime());

      setLeaderboardData(indexedData);
    } catch (error) {
      console.error('Error fetching leaderboard data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Check for cached data
    const cachedData = localStorage.getItem(CACHE_KEY);
    const cachedTimestamp = localStorage.getItem(CACHE_TIMESTAMP_KEY);

    if (cachedData && cachedTimestamp && !isCacheExpired(parseInt(cachedTimestamp))) {
      // If cached data exists and is not expired, use it
      setLeaderboardData(JSON.parse(cachedData));
      setLoading(false);
    } else {
      // Otherwise, fetch fresh data from the API
      fetchLeaderboardData();
    }
  }, []);

  return { leaderboardData, loading };
};

export default useLeaderboardCache;
