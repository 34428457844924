import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Tournaments from './Tournaments';
import Seasons from './Seasons';
import SearchBar from './SearchBar';
import FullLeaderboard from './FullLeaderboard';
import { useTranslation } from './TranslationProvider'; // Import the useTranslation hook
import { useParams, useNavigate } from 'react-router-dom'; // Import react-router hooks
import useLeaderboardCache from './useLeaderboardCache'; // Use the modified cache hook
import './Leaderboards.css'; // Import custom CSS

const Leaderboards = () => {
  const { view, leaderboardId } = useParams(); // Get 'view' and 'leaderboardId' (index) from URL parameters
  const [selectedView, setSelectedView] = useState(view || 'seasons'); // Default to 'seasons' if no view in URL
  const [selectedLeaderboard, setSelectedLeaderboard] = useState(null);
  const { leaderboardData, loading } = useLeaderboardCache(); // Fetch the leaderboard data from cache
  const { translate } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate(); // For programmatic navigation

  // Update selected view when the URL view param changes
  useEffect(() => {
    if (view) {
      setSelectedView(view);
    }
  }, [view]);

  // Load the selected leaderboard from the URL if leaderboardId is present
  useEffect(() => {
    if (leaderboardId && leaderboardData.length > 0) {
      const leaderboardIndex = parseInt(leaderboardId); // Get the index from the URL
      const leaderboard = leaderboardData[leaderboardIndex]; // Select leaderboard by index
      if (leaderboard) {
        setSelectedLeaderboard(leaderboard); // Set the selected leaderboard
      }
    }
  }, [leaderboardId, leaderboardData]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleLeaderboardSelect = (leaderboard) => {
    setSelectedLeaderboard(leaderboard);
    navigate(`/leaderboards/${selectedView}/${leaderboard.id}`); // Add the leaderboard index to the URL
  };

  const handleBack = () => {
    setSelectedLeaderboard(null);
    navigate(`/leaderboards/${selectedView}`); // Remove the leaderboardId from the URL when going back
  };

  const handleViewChange = (newView) => {
    setSelectedView(newView);
    navigate(`/leaderboards/${newView}`); // Update the URL when changing views
  };

  return (
    <Container className="leaderboards-container">
      {!selectedLeaderboard ? (
        <>
          <Row className="justify-content-center">
            <SearchBar
              className="leaderboards-search-bar"
              searchTerm={searchTerm}
              onChange={handleSearchChange}
              placeholder={translate('searchLeaderboards')}
            />
          </Row>

          <Row className="toggle-text-container">
            <Col xs={5} className="text-end">
              <span
                className={`toggle-text ${selectedView === 'tournaments' ? 'active' : ''}`}
                onClick={() => handleViewChange('tournaments')}
              >
                {translate('tournaments')}
              </span>
            </Col>
            <Col xs={2} className="text-center dot">
              •
            </Col>
            <Col xs={5} className="text-start">
              <span
                className={`toggle-text ${selectedView === 'seasons' ? 'active' : ''}`}
                onClick={() => handleViewChange('seasons')}
              >
                {translate('seasons')}
              </span>
            </Col>
          </Row>

          <div className="leaderboards-content">
            {selectedView === 'tournaments' && (
              <Tournaments onSelectLeaderboard={handleLeaderboardSelect} searchTerm={searchTerm} />
            )}
            {selectedView === 'seasons' && (
              <Seasons onSelectLeaderboard={handleLeaderboardSelect} searchTerm={searchTerm} />
            )}
          </div>
        </>
      ) : (
        <FullLeaderboard
          title={selectedLeaderboard.title}
          data={selectedLeaderboard.data}
          address={selectedLeaderboard.address}
          link={selectedLeaderboard.link}
          onBack={handleBack}
          fullDisplay={true}
        />
      )}
    </Container>
  );
};

export default Leaderboards;
