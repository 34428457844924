import React from 'react';
import { motion } from 'framer-motion';
import { Col, Container, Row } from 'react-bootstrap';

const Section = ({ children, direction = 'left' }) => {
  const variants = {
    hidden: {
      opacity: 0,
      x: direction === 'left' ? -100 : 100, // Slide in from left or right
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: 'spring',
        stiffness: 100,
        damping: 20,
      },
    },
  };

  return (
    <Container fluid className="my-5">
      <Row className="justify-content-center">
        <Col md={8}>
          {/* Animated motion div */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            variants={variants}
          >
            {children}
          </motion.div>
        </Col>
      </Row>
    </Container>
  );
};

export default Section;
