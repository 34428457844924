import React from 'react';
import { BiArrowBack } from 'react-icons/bi'; // Import the back arrow icon

const BackArrow = ({ size = 48, color = "#1173bd", onClick }) => {
  return (
    <div
      onClick={onClick}
      className="back-arrow"
      style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
    >
      <BiArrowBack size={size} style={{ color: color }} /> {/* Use props for size and color */}
    </div>
  );
};

export default BackArrow;
