import React, { useState, useEffect } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import { Container, Row } from 'react-bootstrap';
import SearchBar from './SearchBar';
import { useTranslation } from './TranslationProvider';

// Import the custom marker icon
import googleMapIcon from '../images/googleMap.png';

const MapPage = () => {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { translate } = useTranslation();

  useEffect(() => {
    // Fetch the leaderboard data from the server
    const fetchData = async () => {
      try {
        const response = await axios.get('api/map/leaderboard-coordinates');
        setLeaderboardData(response.data);
        setFilteredData(response.data); // Initially display all data
      } catch (error) {
        console.error('Error fetching leaderboard data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Initialize the map
    const map = L.map('map').setView([51.505, -0.09], 5); // Default center and zoom

    // Add OpenStreetMap tiles
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);

    // Define the custom marker icon using googleMapIcon
    const customIcon = L.icon({
      iconUrl: googleMapIcon,
      iconSize: [32, 32], // Size of the icon
      iconAnchor: [16, 32], // Point of the icon which will correspond to marker's location
      popupAnchor: [0, -32], // Point from which the popup should open relative to the iconAnchor
    });

    // Add markers for each location using the custom icon
    filteredData.forEach((location) => {
      L.marker([location.addressCoordinates.lat, location.addressCoordinates.lng], { icon: customIcon })
        .addTo(map)
        .bindPopup("<b>" + location.location + "</b><br />" + location.address);
    });

    // Adjust the map bounds to fit all locations
    if (filteredData.length > 0) {
      const bounds = new L.LatLngBounds(
        filteredData.map(location => [location.addressCoordinates.lat, location.addressCoordinates.lng])
      );
      map.fitBounds(bounds);
    }

    // Cleanup the map when component unmounts
    return () => {
      map.remove();
    };
  }, [filteredData]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    // Filter the leaderboard data based on the search term
    const filtered = leaderboardData.filter((leaderboard) =>
      leaderboard.title.toLowerCase().includes(value.toLowerCase()) ||
      leaderboard.address.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  return (
    <Container>
      {/* Search Bar */}
      <Row className="justify-content-center mb-4">
        <SearchBar
          searchTerm={searchTerm}
          onChange={handleSearchChange}
          placeholder={translate('searchLeaderboards')}
        />
      </Row>

      {/* Leaflet Map */}
      <div id="map" style={{ height: '600px', width: '100%' }}></div>
    </Container>
  );
};

export default MapPage;
