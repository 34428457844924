import React, { useState } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from './TranslationProvider';

const ContactForm = ({ onClose }) => {
  const { translate } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    otherSubject: '',
    description: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');

  const validate = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = translate("nameRequired");
    }

    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = translate("invalidEmail");
    }

    if (formData.phone && !/^\+?\d{10,15}$/.test(formData.phone)) {
      newErrors.phone = translate("invalidPhone");
    }

    if (!formData.subject) {
      newErrors.subject = translate("subjectRequired");
    }

    if (formData.subject === 'Other' && !formData.otherSubject.trim()) {
      newErrors.otherSubject = translate("otherSubjectRequired");
    }

    if (!formData.description.trim()) {
      newErrors.description = translate("messageRequired");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      return;
    }

    setLoading(true);
    setStatus('Sending...');

    try {
      const response = await fetch('/api/contact/post', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          subject: formData.subject === 'Other' ? formData.otherSubject : formData.subject,
          description: formData.description,
        }),
      });

      if (response.ok) {
        setStatus('Message sent successfully!');
        setLoading(false);
        setTimeout(onClose, 2000); // Close the modal after a delay
      } else {
        setStatus('Failed to send message. Please try again.');
        setLoading(false);
      }
    } catch (error) {
      setStatus('Failed to send message. Please try again.');
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit} data-bs-theme="light" className='contact-us'>
      <Row>
        <Col xs={12}>
          <Form.Group controlId="formName">
            <Form.Label>{translate("name")}</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              isInvalid={!!errors.name}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors.name}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col xs={12}>
          <Form.Group controlId="formEmail">
            <Form.Label>{translate("email")}</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col xs={12}>
          <Form.Group controlId="formPhone">
            <Form.Label>{translate("phone")}</Form.Label>
            <Form.Control
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              isInvalid={!!errors.phone}
            />
            <Form.Control.Feedback type="invalid">
              {errors.phone}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col xs={12}>
          <Form.Group controlId="formSubject">
            <Form.Label>{translate("subject")}</Form.Label>
            <Form.Control
              as="select"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              isInvalid={!!errors.subject}
              required
            >
              <option value="">{translate("selectSubject")}</option>
              <option value="Organize">{translate("organizeEvents")}</option>
              <option value="PlayBeyblade">{translate("playBeyblade")}</option>
              <option value="Mistake">{translate("mistake")}</option>
              <option value="XProSub">{translate("xProSub")}</option>
              <option value="XTeamsSub">{translate("xTeamsSub")}</option>
              <option value="wantToSponsor">{translate("wantToSponsor")}</option>
              <option value="feedback">{translate("feedback")}</option>
              <option value="Other">{translate("other")}</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.subject}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        {formData.subject === 'Other' && (
          <Col xs={12}>
            <Form.Group controlId="formOtherSubject">
              <Form.Label>{translate("otherSubject")}</Form.Label>
              <Form.Control
                type="text"
                name="otherSubject"
                value={formData.otherSubject}
                onChange={handleChange}
                isInvalid={!!errors.otherSubject}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.otherSubject}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        )}

        <Col xs={12}>
          <Form.Group controlId="formDescription">
            <Form.Label>{translate("message")}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              value={formData.description}
              onChange={handleChange}
              isInvalid={!!errors.description}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors.description}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <div className="d-flex justify-content-between mt-4">
        {onClose ? (
          <Button variant="secondary" onClick={onClose}>
            {translate("cancel")}
          </Button>)
          : (<></>)
        }
        <Button type="submit" variant="primary" disabled={loading}>
          {loading ? translate("sending") : translate("submit")}
        </Button>
      </div>
      {status && <p className="mt-3">{status}</p>}
    </Form>
  );
};

export default ContactForm;
