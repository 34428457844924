import React, { useState } from 'react';
import { Table, Card, Collapse, Button } from 'react-bootstrap';
import { useTranslation } from './TranslationProvider';

const ScheduleTable = ({ schedule }) => {
  const [open, setOpen] = useState(false);
  const { translate } = useTranslation();

  return (
    <Card>
      <Card.Header onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
        <Button
          variant="link"
          onClick={() => setOpen(!open)}
          aria-controls="collapse-table"
          aria-expanded={open}
        >
          {translate('history')}
        </Button>
      </Card.Header>
      <Collapse in={open}>
        <div id="collapse-table">
          <Card.Body>
            <Table striped bordered hover>
              <thead className="table-header">
                <tr>
                  <th>{translate('date')}</th>
                  <th>{translate('challongelink')}</th>
                </tr>
              </thead>
              <tbody>
                {schedule.map((item, index) => (
                  <tr key={index}>
                    <td>{item.date}</td>
                    <td><a href={item.link} target="_blank" rel="noopener noreferrer">{item.name}</a></td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </div>
      </Collapse>
    </Card>
  );
};

export default ScheduleTable;
