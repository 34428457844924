import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import VideoFeed from './VideoFeed';
import Leaderboard from './Leaderboard';
import ScheduleTable from './ScheduleTable';
import TeamGrid from './TeamGrid';
import { useTranslation } from './TranslationProvider';
import xpatriotimg from '../images/XPatriot_black.jpg';
import axios from 'axios';
import { useEffect, useState } from 'react';

const XTeams = () => {
  const { translate } = useTranslation();
  const videoId = "swXIZg0ETOw"; // Replace with actual video ID

  const leaderboardDataUrl = 'https://sheets.googleapis.com/v4/spreadsheets/1OyRA_tjEWd-8gVK1i1v7xvwBWyEvYgbaEuTv0CV6Asg/values/BeyparcQC_2024?key=AIzaSyBPV1_l6CJo6_ts4ySA1YTNWCsWF0qh1hc';

  const schedule = [
    { date: '2024-08-15', name: 'Tournoi X Wild', link: 'https://challonge.com/your-tournament' },
    // Add more schedule items as needed
  ];
  const teams = [
    { name: 'X Patriot', logo: xpatriotimg },
    { name: 'X Patriot', logo: xpatriotimg },
    { name: 'X Patriot', logo: xpatriotimg },
    // Add more teams as needed
  ];

  return (
    <Container style={{ padding: '24px' }}>
      <Row className="mb-4">
        <Col>
          <h3>{translate('lastmatches')}</h3>
          <VideoFeed playlistId='PLsncmYcY_Pb7n-E2isV1UcwHyMsklOLSX' apiKey='AIzaSyBPV1_l6CJo6_ts4ySA1YTNWCsWF0qh1hc' />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          {/*<Leaderboard fetchUrl={leaderboardDataUrl} columnNb='8' title={translate('xteamsleaderboard')}> */}
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <ScheduleTable schedule={schedule} />
        </Col>
      </Row>
      
      <Row>
        <Col>
          <h3>{translate('participatingteams')}</h3>

          <TeamGrid teams={teams} />
        </Col>
      </Row>
    </Container>
  );
};

export default XTeams;
