import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from './TranslationProvider';

const Copyright = () => {
  const { translate } = useTranslation();

  return (
    <Container className="py-5 copyright-page" style={{ padding: '24px' }}>
      <h2>{translate('copyrightNoticeTitle')}</h2>
      <p>
        {translate('copyrightMessage')}
      </p>
      <h2>
        {translate('iconsSource')}
      </h2>
      <p>
        <a href="https://www.flaticon.com/free-icons/world-financial" title="world-financial icons">World-financial icons created by Mayor Icons - Flaticon</a>
      </p>
    </Container>
  );
};

export default Copyright;