import React, { useEffect, useState } from 'react';
import YouTube from 'react-youtube';
import axios from 'axios';

const VideoFeed = ({ playlistId, apiKey }) => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=20&playlistId=${playlistId}&key=${apiKey}`
        );
        setVideos(response.data.items);
      } catch (error) {
        console.error('Error fetching videos', error);
      }
    };

    fetchVideos();
  }, [playlistId, apiKey]);

  return (
    <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', padding: '10px 0' }}>
      {videos.map((video, index) => (
        <div key={index} style={{ display: 'inline-block', marginRight: '16px' }}>
          <YouTube videoId={video.snippet.resourceId.videoId} opts={{ width: '300px', height: '200px' }} />
          <p style={{ width: '300px', textAlign: 'center' }}>{video.snippet.title}</p>
        </div>
      ))}
    </div>
  );
};

export default VideoFeed;
