import React from 'react';
import TopBar from './components/TopBar';
import Seasons from './components/Seasons';
import Tournaments from './components/Tournaments';
import XTeams from './components/XTeams';
import Footer from './components/Footer';
import Copyright from './components/Copyright';
import { TranslationProvider } from './components/TranslationProvider';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import NewsTicker from './components/NewsTicker';
import CalendarPage from './components/CalendarPage';
import ContactUsPage from './components/ContactUsPage';
import Leaderboards from './components/Leaderboards';

import './styles.css'
import HomePage from './components/HomePage';
import MapPage from './components/MapPage';

const App = () => {
  return (
    <TranslationProvider>
      <Router>
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <NewsTicker />
          <TopBar />
          <div className="content">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/xteams" element={<XTeams />} />
              <Route path="/leaderboards/:view/:leaderboardId" element={<Leaderboards />} />
              <Route path="/leaderboards/:view" element={<Leaderboards />} />
              <Route path="/leaderboards" element={<Leaderboards />} />
              <Route path="/map" element={<MapPage />} />
              <Route path="/calendar" element={<CalendarPage />} />
              <Route path="/contact" element={<ContactUsPage />} />
              <Route path="/copyright" element={<Copyright />} />
              {/* Add other routes as needed */}
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </TranslationProvider>
  );
};

export default App;
