import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Leaderboard from './Leaderboard';
import Loading from './Loading';
import useLeaderboardCache from './useLeaderboardCache';

const Seasons = ({ onSelectLeaderboard, searchTerm }) => {
  const { leaderboardData, loading } = useLeaderboardCache();

  if (loading) {
    return <Loading style={{ paddingTop: '3rem' }} />;
  }

  const seasons = leaderboardData.filter((leaderboard) => leaderboard.type === 'Cumulatif');

  const renderLeaderboards = () => {
    return seasons.map((leaderboard, index) => (
      <Col
        key={index}
        lg={6}
        md={8}
        sm={12}
        className="d-flex justify-content-center mb-4"
        onClick={() => onSelectLeaderboard(leaderboard)}
      >
        <Leaderboard title={leaderboard.title} data={leaderboard.data} searchTerm={searchTerm} />
      </Col>
    ));
  };

  return (
    <Container style={{ padding: '24px' }}>
      <Row className="justify-content-center g-4">
        {renderLeaderboards()}
      </Row>
    </Container>
  );
};

export default Seasons;
