import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
// center the title and remove borders
const TeamGrid = ({ teams }) => {
  return (
    <Row>
      {teams.map((team, index) => (
        <Col key={index} xs={12} md={6} lg={4} className="mb-4">
            <Card className="h-100">
                <Card.Img variant="top" src={team.logo} style={{ height: '200px', objectFit: 'contain' }} />
                <Card.Body>
                    <Card.Title>{team.name}</Card.Title>
                </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default TeamGrid;
