import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const EventDetailsCard = ({ event, onClose }) => {
  // Function to safely render URLs in description as clickable links
  const renderDescriptionWithLinks = (description) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g; // Simple URL pattern
    return description.split('\n').map((line, index) => (
      <p key={index}>
        {line.split(urlPattern).map((part, i) =>
          urlPattern.test(part) ? (
            <a
              key={i}
              href={part}
              target="_blank"
              rel="noopener noreferrer"
              style={{ wordBreak: 'break-all' }} // Ensure URLs wrap within the container
            >
              {part}
            </a>
          ) : (
            part
          )
        )}
      </p>
    ));
  };

  const formattedStartDate = new Date(event.start._date).toLocaleString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true, // For AM/PM format
  });
  const formattedEndDate = new Date(event.end._date).toLocaleString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true, // For AM/PM format
  });


  return (
    <Modal show={true} onHide={onClose} className='event-details-card-container' data-bs-theme="dark" centered>
      <Modal.Header className='event-details-card event-details-card-title text-center' style={{ width: '100%' }} closeButton>
        <Modal.Title>{event.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='event-details-card' style={{ wordWrap: 'break-word' }}>
        {renderDescriptionWithLinks(event.body)}
      </Modal.Body>
      <Modal.Footer className='event-details-card'>
        <div className="text-center" style={{ width: '100%' }}>
          <p><strong>Start:</strong> {formattedStartDate}</p>
          <p><strong>End:</strong> {formattedEndDate}</p>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EventDetailsCard;
