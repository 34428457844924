import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom'; 
import { useTranslation } from './TranslationProvider';
import LanguageSwitcher from './LanguageSwitcher';
import SocialMediaIcons from './SocialMediaIcons';
import { ReactComponent as Logo } from '../images/xalliance.svg';

const TopBar = () => {
  const { translate } = useTranslation();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(location.hash || '#home');
  const [menuExpanded, setMenuExpanded] = useState(false);

  // List of valid hashes
  const validHashes = ['#leaderboards', '#map', '#calendar', '#contact', '#home'];

  // Update currentPage based on location.hash if valid
  useEffect(() => {
    if (validHashes.includes(location.hash)) {
      setCurrentPage(location.hash);
    }
  }, [location]);

  // Handle link clicks and update currentPage
  const handleNavClick = (hash) => {
    setCurrentPage(hash); // Set the clicked link as the current page
    window.location.hash = hash; // Update the URL hash
    setMenuExpanded(false); // Optionally collapse the menu
  };

  const isHome = currentPage === '#home';

  return (
    <Navbar expand="lg" className="metallic top-bar" variant='dark' expanded={menuExpanded}>
      <Container fluid className="justify-content-between align-items-center">
        <Navbar.Brand onClick={() => handleNavClick('#home')}>
          <Logo height="80" width="102" className="d-inline-block align-top" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setMenuExpanded(!menuExpanded)} />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
          <Nav className="mx-auto custom-nav" style={{ display: 'flex', justifyContent: 'center', paddingLeft: 50 }}>
            <Nav.Link 
              href="#leaderboards" 
              className="nav-link-item"
              active={currentPage === '#leaderboards' && !isHome}
              onClick={() => handleNavClick('#leaderboards')}
            >
              {translate('leaderboards')}
            </Nav.Link>
            <Nav.Link 
              href="#map" 
              className="nav-link-item"
              active={currentPage === '#map' && !isHome}
              onClick={() => handleNavClick('#map')}
            >
              {translate('map')}
            </Nav.Link>
            <Nav.Link 
              href="#calendar" 
              className="nav-link-item"
              active={currentPage === '#calendar' && !isHome}
              onClick={() => handleNavClick('#calendar')}
            >
              {translate('calendar')}
            </Nav.Link>
            <Nav.Link 
              href="#contact" 
              className="nav-link-item"
              active={currentPage === '#contact' && !isHome}
              onClick={() => handleNavClick('#contact')}
            >
              {translate('contactUs')}
            </Nav.Link>
            <div className="d-flex align-items-center language-switcher">
              <LanguageSwitcher />
            </div>
          </Nav>
          <SocialMediaIcons className="d-flex align-items-center media-icon" />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default TopBar;
