import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import goldMedal from '../images/goldMedal.png';
import silverMedal from '../images/silverMedal.png';
import bronzeMedal from '../images/bronzeMedal.png';

const TournamentCard = ({ tournament, onClick }) => {
  const { title, season, data } = tournament;


  // Find the top 3 players based on columns E (rank 1), F (rank 2), and G (rank 3)
  const top3 = [];
  data.forEach(row => {
    if (row[4] === "1") top3[0] = row[1]; // Column E (index 4) for rank 1
    if (row[5] === "1") top3[1] = row[1]; // Column F (index 5) for rank 2
    if (row[6] === "1") top3[2] = row[1]; // Column G (index 6) for rank 3
  });

  return (
    <Card onClick={onClick} style={{ cursor: 'pointer' }} className="tournament-card">
      <Card.Header as="h5" className="tournament-card-title" style={{ minWidth:"200px", display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
        <Row>{title} {/* Display title only */}</Row>
      </Card.Header>
      <Card.Body>
        <Row className="justify-content-center">
          <Col className="text-center">
            <img src={goldMedal} alt="Gold Medal" style={{ width: '48px', height: '48px' }} />
            <div>{top3[0] || 'N/A'}</div> {/* Display 1st place or N/A if not found */}
          </Col>
          <Col className="text-center">
            <img src={silverMedal} alt="Silver Medal" style={{ width: '48px', height: '48px' }} />
            <div>{top3[1] || 'N/A'}</div> {/* Display 2nd place or N/A if not found */}
          </Col>
          <Col className="text-center">
            <img src={bronzeMedal} alt="Bronze Medal" style={{ width: '48px', height: '48px' }} />
            <div>{top3[2] || 'N/A'}</div> {/* Display 3rd place or N/A if not found */}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default TournamentCard;
